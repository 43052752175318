/* src/styles/tailwind.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
  /* .header {
    @apply text-[34px] sm:text-[36px] md:text-[38px] lg:text-[40px] xl:text-[42px] 2xl:text-[44px] font-sintony font-bold text-textColor tracking-wide;
  }
  .l2header {
    @apply text-[30px] sm:text-[32px] md:text-[34px] lg:text-[38px] xl:text-[40px] 2xl:text-[42px] font-sintony font-bold text-textColor tracking-wide;
  }
  .l3header {
    @apply text-[26px] sm:text-[28px] md:text-[30px] lg:text-[32px] xl:text-[34px] 2xl:text-[36px] font-sintony font-bold text-textColor tracking-wide;
  }
  .mediumHeader {
    @apply text-[20px] sm:text-[22px] md:text-[24px] lg:text-[26px] xl:text-[28px] 2xl:text-[30px] font-sintony font-bold text-textColor tracking-wide;
  }
  .subHeader {
    @apply text-[18px] sm:text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px] 2xl:text-[28px] font-sintony font-bold text-textColor tracking-wide;
  }
  .tagline {
    @apply text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px] xl:text-[24px] 2xl:text-[26px] font-sintony font-normal text-textColor tracking-wide;
  }
  .paragraph {
    @apply text-[14px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] 2xl:text-[24px] font-sintony font-normal text-textColor tracking-wide;
  }
  .extraSmall {
    @apply text-[8px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[18px] 2xl:text-[20px] font-sintony font-normal text-textColor tracking-wide;
  } */

  .newHeader {
    @apply text-[30px] md:text-[33px] lg:text-[36px] leading-[38px] md:leading-[41px] lg:leading-[44px] font-sintony font-semibold text-textColor tracking-wide;
  }

  .newSubHeader {
    @apply text-[24px] md:text-[27px] lg:text-[30px] leading-[32px] md:leading-[35px] lg:leading-[38px] font-sintony font-semibold text-textColor tracking-wide;
  }

  .newL2SubHeader {
    @apply text-[20px] md:text-[22px] lg:text-[24px] leading-[30px] md:leading-[31px] lg:leading-[32px] font-sintony font-semibold text-textColor tracking-wide;
  }

  .newL3SubHeader {
    @apply text-[18px] md:text-[19px] lg:text-[20px] leading-[28px] md:leading-[29px] lg:leading-[30px] font-sintony font-semibold text-textColor tracking-wide;
  }

  .newContent {
    @apply text-[18px] md:text-[19px] lg:text-[20px] leading-[28px] md:leading-[29px] lg:leading-[30px] font-sintony font-normal text-textColor tracking-wide;
  }

  .newL2Content {
    @apply text-[16px] leading-[24px] font-sintony font-normal text-textColor tracking-wide;
  }

  /* .container {
    @apply w-full h-full py-6 px-4 sm:px-10 sm:py-10 md:px-14 md:py-14 lg:px-16 lg:py-16 xl:px-20 xl:py-20 2xl:px-28 2xl:py-28;
  } */

  .container {
    @apply w-full h-full py-6 px-4 md:px-8 md:py-14 lg:max-w-[1440px] lg:px-12 lg:py-24;
  }

  .center {
    @apply flex justify-center items-center w-full h-auto;
  }

  .margin {
    @apply mt-2 sm:mt-3 md:mt-4 lg:mt-5 xl:mt-6 2xl:mt-7;
  }
}

/* CustomScrollbar.css */
.custom-scrollbar {
  overflow: auto;
  scrollbar-width: thin; /* Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 50px; /* Width of the entire scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #000; /* Color of the thumb */
  border-radius: 20px; /* Rounded corners of the thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the track */
  border-radius: 20px; /* Rounded corners of the track */
}

/* HiddenScrollbar.css */
.hidden-scrollbar {
  overflow: auto;
  scrollbar-width: thin; /* Firefox */
}

.hidden-scrollbar::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  height: 10px; /* Height of the scrollbar */
}

.hidden-scrollbar::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set thumb color to transparent */
  border-radius: 10px; /* Rounded corners of the thumb */
}

.hidden-scrollbar::-webkit-scrollbar-track {
  background-color: transparent; /* Set track color to transparent */
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}


@keyframes moveArrow {
  0% {
    transform: translateX(0); /* Arrow starts on the left */
  }
  100% {
    transform: translateX(200px); /* Arrow moves to the right */
  }
}

@keyframes revealText {
  0% {
    transform: translateX(50px); /* Text starts to the right */
    opacity: 0; /* Text is hidden */
  }
  100% {
    transform: translateX(0); /* Text moves to the left */
    opacity: 1; /* Text becomes visible */
  }
}

/* Tailwind utility classes */
.animate-moveArrow {
  animation: moveArrow 2s ease-in-out forwards;
}

.animate-revealText {
  animation: revealText 2s ease-in-out forwards;
  animation-delay: 0.2s; /* Sync with arrow animation */
}